/**
 * element-ui插件
 * @author 薛宣毅
 */
import Vue from 'vue'
import ElementUI from 'element-ui'

import ElFormModel from 'el-form-model'
import 'element-ui/lib/theme-chalk/index.css'

/**
 * 语言
 */
import lang from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'

/**
 * 全局样式
 */
import './element-variables.scss'

import 'element-ui/lib/theme-chalk/icon.css'
// 设置语言
locale.use(lang)

Vue.use(ElementUI)
Vue.use(ElFormModel)
Vue.use(ElementUI, { locale })
