<template>
  <el-container class="my-app">
    <el-aside width="248px" class="menu-left">
      <div class="head-img">
        <img src="@/assets/logo.png" alt="">
      </div>
      <div class="head-name">
        管理员
      </div>
      <div class="my-menu">
        <el-menu
          :default-active="activeName"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
<!--          <router-link tag="li" to="/summaryData">-->
<!--            <el-menu-item index="/summaryData">-->
<!--              <span slot="title">汇总数据</span>-->
<!--            </el-menu-item>-->
<!--          </router-link>-->
          <router-link tag="li" to="/company">
            <el-menu-item index="/company">
              <span slot="title">工厂管理</span>
            </el-menu-item>
          </router-link>
<!--          <router-link tag="li" to="/weighingPlace">-->
<!--            <el-menu-item index="/weighingPlace">-->
<!--              <span slot="title">称量处管理</span>-->
<!--            </el-menu-item>-->
<!--          </router-link>-->
          <router-link tag="li" to="/waste">
            <el-menu-item index="/waste">
              <span slot="title">废弃物管理</span>
            </el-menu-item>
          </router-link>
          <router-link tag="li" to="/billingwWay">
            <el-menu-item index="/billingwWay">
              <span slot="title">费用计算方式</span>
            </el-menu-item>
          </router-link>
        </el-menu>
      </div>
    </el-aside>
    <el-main class="my-main">
      <div class="my-main-route">
        <router-view></router-view>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "index",
	data() {
		return {
			activeName: this.$route.path
		}
	},
  created() {
    console.log(this.$route.path)
  },
  methods: {
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		}
	}
}
</script>

<style lang="scss" scoped>
.my-app {
  .menu-left {
    .head-img>img {
      height: 80px;
      width: 80px;
      margin-top: 68px;
      margin-left: 68px;
      border-radius: 50%;
    }
    .head-name {
      width: 216px;
      height: 28px;
      font-family: PingFang SC;
      color: #ffffff;
      font-size: 20px;
      text-align: center;
      margin-top: 17px;
    }
    .my-menu {
      margin-top: 60px;
    }
  }
  min-height: 100%;
  bottom: 0;
  .my-main {
    width: 1645px;
    padding-top: 18px;
    padding-bottom: 18px;
    background-color: #706ed5;
    .my-main-route {
      height: 100%;
      background-color: white;
      border-radius: 20px;
    }
  }
  .menu-left {
    background-color: #706ed5;
  }
}

  .el-menu {
    background-color: #706ed5;
    border-right: solid 0px;
  }
  ////
  //.el-menu-item.is-active {
  //  color: white;
  //}
  ////
  //.el-menu-item.is-active i {
  //  color: white !important;
  //}
  //.el-menu-item i {
  //  color: #706ed5;
  //}
  //
  .el-menu-item {
    border-radius: 0 30px 30px 0;
    //color: white;
  }
  //
  //.el-menu-item:hover {
  //  color: #696969;
  //}

</style>
