
const LIST_ITEM= {
    state:()=>({
        maintenance:null,
        flagFalse:false
    }),
    mutations:{
        listItem(state,payload){
            console.log(state,payload)
            state.maintenance=payload.maintenance;
            state.flagFalse=payload.flagFalse;
        }
    }
}
export default LIST_ITEM;