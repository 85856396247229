import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import '@/plugins/element-ui/element-ui.js'



// 添加请求拦截器
axios.interceptors.request.use(
  config => {
    // 在发送请求之前做一些事情
    let auth_info = localStorage.getItem('auth_info') ? JSON.parse(localStorage.getItem('auth_info')).accessToken : ''
    if (auth_info) {
      config.headers.authorization = localStorage.getItem('auth_info') ? JSON.parse(localStorage.getItem('auth_info')).accessToken : ''
    }
    console.error('config', config)
    return config
  },
  error => {
    //做一些有请求错误的事情
    console.error('request error', error)
    return Promise.reject(error);
  }
)

// response 拦截器
axios.interceptors.response.use(
  response => {
    //使用响应数据返回响应；
      console.error('response', response)
      if ( response.data.code == 401008 || response.data.code == 401001 ) {
        localStorage.removeItem('auth_info')
        location.replace('/');
      }
      return response
  },
  error =>{
    //使用响应错误返回
    console.log('errosssssssssssr', error);
    return Promise.reject(error.response.data)
  }
)

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
