/**
 * 全局路由组(引入分包路由组)
 * @author 薛宣毅
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Layouts from "@/layouts";

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Layouts,
    children: [
      {
        path: '/weighingPoint',
        name: 'weighingPoint',
        component: () => import('../views/weighingPoint')
      },
      {
        path: '/weighingPlace',
        name: 'weighingPlace',
        component: () => import('../views/weighingPlace')
      },
      {
        path: '/weighingPointList',
        name: 'weighingPointList',
        component: () => import('../views/weighingPointList')
      },
      {
        path: '/weighingPlaceList',
        name: 'weighingPlaceList',
        component: () => import('../views/weighingPlaceList')
      },
      {
        path: '/waste',
        name: 'waste',
        component: () => import('../views/waste')
      },
      {
        path: '/billingwWay',
        name: 'waste',
        component: () => import('../views/billingwWay')
      },
      {
        path: '/company',
        name: 'company',
        component: () => import('../views/company')
      },
      {
        path: '/companyInfo',
        name: 'companyInfo',
        component: () => import('../views/companyInfo')
      },
      {
        path: '/',
        name: 'company',
        component: () => import('../views/company')
      }
    ]
  },
  {
    path: '/summaryData',
    name: 'summaryData',
    component: () => import(/* webpackChunkName: "about" */ '../views/summaryData')
  },
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login')
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
