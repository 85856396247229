/**
 * vuex全局入口
 * @author 薛宣毅
 * @notice modules分包引入
 */
import Vue from "vue";
import Vuex from "vuex";
import LIST_ITEM from "@/store/listItem";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {LIST_ITEM},
  strict: debug,
});
